console.log(location)
console.log(location.pathname)

import Vue from 'vue'

var pages = ['/priceMain.html', '/priceLabour.html', '/priceMaterial.html', '/aboutUs.html','/contactUs.html','/mlMain.html','/mlLabour.html','/mlMaterial.html','/mlCloud.html']
var pageIndex = pages.indexOf(location.pathname);
console.log(pageIndex)

const NavComponent = Vue.extend({ 
  template: `
  <div class="nav pc-shadow index-nav-bg" :style="navBackgroundStyle">
    <div class="inner-c nav-c">
      <!-- header 的左侧 -->
      <div class="nav-left">
        <!-- logo 区域 -->
        <h1 class="logo">
          <a class="logo-icon"></a>
        </h1>

      </div>

    </div>

  </div>
  `,

      // <el-dialog
    //   title="请使用企业微信扫码登录"
    //   width="360px"
    //   class="home-dialog"
    //   :visible.sync="dialogVisible"
    // >
    //   <img style="cursor: pointer;" src="/images/qrcode_8cm.png" alt="" srcset="">
    // </el-dialog>

  data() {
    return {
      navBackgroundStyle: '',
      productCard:false,
      priceCard: false,
      contactCard:false,
      dialogVisible: false
    }
  },
  mounted() {
    addEventListener('scroll',this.handelScroll)
  },
  methods: {   
    // <div class="nav-right-box" @click="loginBtn">
    //   <div class="right-top-login-btn">企业微信登录</div>
    // </div>
   
    //  监听鼠标滚动事件  // e = e || window.event
    handelScroll(){
      if (scrollY !== 0) {
        this.navBackgroundStyle = {background:'#fff'}
      }else {
        this.navBackgroundStyle = ''
      }
    },
    leaveNavUl(){
      console.log('leave-ul')
      this.priceCard = false
      this.productCard = false
      this.contactCard = false
    },
    handelNav(value){
      console.log('leave-li')
      if ( value === 'productCard') {
        this.priceCard = false
        this.contactCard = false
      }else if ( value === 'priceCard') {
        this.productCard = false
        this.contactCard = false
      }else if ( value === 'contactCard') {
        this.productCard = false
        this.priceCard = false
      }
      this[value] = this[value]? false: true
    },
    handleClose(){
      this.dialogVisible = false
    },
    loginBtn() {
      // this.dialogVisible = true
      window.open('https://saas.ddscan.cn')
      // window.open("https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=wwf0c2699f5f403fce&redirect_uri=https%3A%2F%2Fsaash5.ddscan.cn&state=wxbac4ee8f94ee3054&usertype=member");
    },
  }
})

export default NavComponent