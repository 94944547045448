import axios from "axios";

const baseURL = 'https://api.mid.maliyun.cn/malihome'
axios.defaults.baseURL = baseURL;
/* 获取产品详情
{
  "product_tag": "MaliGZ"
}
*/
export const getProductByTag = async (data) => {
  return new Promise((resolve, reject) => {
    axios.post('/product/get_product', data).then((res) => {
      if (res.code && res.msg) {
        reject(res)
      } else {
        resolve(res.data)
      }
    })
  })
}
/* 获取配置
{
  platform_tag string 平台标识 MaliHome
  conf_names array[string] 配置名数组 如果不传则获取全部
}
*/
export const getConfigByNames = async (data) => {
  return new Promise((resolve, reject) => {
    axios.post('/config/get_config', data).then((res) => {
      if (res.code && res.msg) {
        reject(res)
      } else {
        resolve(res)
      }
    })
  })
}
/* 获取Banner
{
  platform_tag string 平台标识 MaliHome
  banner_tag string banner标识 用于调用
}
*/
export const getBannerByTag = async (data) => {
  return new Promise((resolve, reject) => {
    axios.post('/banner/get_banner', data).then((res) => {
      if (res.code && res.msg) {
        reject(res)
      } else {
        resolve(res)
      }
    })
  })
}