<template>
  <div id="indexApp" v-cloak>
    <div id="navBox"></div>
    <div class="login-banner">
      <div class="inner-c" style="text-align: center;">
        <img src="/images/labour/hk-banner.png" />
      </div>
      <div :class="secondaryClass">
        <ul class="price-main-nav-new" ref="priceMainRef">
          <li
            :class="item.className"
            v-for="(item, index) in priceMainList"
            @click="handellabourNav(index, item)"
            :key="index"
          >
            {{ item.text }}
            <div class="current-border"></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="content content-c">
      <div class="content-branch">
        <h2 class="content-h2-title" ref="changeUsRef">行业痛点</h2>
        <div class="price-main-content-middle">
          <div
            v-for="(item, index) in painSpotList"
            :key="index"
            class="subheading-box"
            :class="item.align"
            :style="{ top: item.top, left: item.left }"
          >
            <div class="subheading-title">
              {{ item.title }}
            </div>
            <div class="subheading-content">
              <p
                style="width: 170px"
                :style="{ width: item.width ? item.width : '170px' }"
              >
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="advantage-background">
        <div class="content-branch">
          <h2 class="content-h2-title" ref="mlAdvantageRef">四大优势</h2>
          <div class="width-100 advantage-content">
            <div
              v-for="(item, index) in advantageList"
              :key="index"
              class="advantage-box pc-shadow-blck abour-advantage-box"
            >
              <div class="box abour-box">
                <div class="advantage-box-title">
                  {{ item.title }}
                  <div class="title-border-left"></div>
                </div>
                <div class="advantage-box-content">
                  {{ item.content }}
                </div>
                <div class="labour-ol-list">
                  <ol>
                    <li v-for="(item1, i) in item.ol" :key="i">
                      <i></i>{{ item1 }}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-branch">
        <div class="price-main-content-bottom" ref="traitRef">
          <h2 style="font-size: 28px; font-weight: 400">功能特点</h2>
          <div class="characteristic-box">
            <div class="box-img">
              <img
                src="/images/labour/6-laborservice_characteristics_picture_costearlywarning.png"
                alt=""
              />
            </div>
            <div class="box-content labour-characteristic-box">
              <h2>费用预警</h2>
              <p>
                针对劳务费用采用实时预警功能，监管项目劳务申请费用远超或远小于实际出勤费用的问题，及时进行成本风险管控。
              </p>
              <ol>
                <li><i></i>对虚报工程量实时预警</li>
                <li><i></i>劳务费用申请实时预警</li>
                <li><i></i>有效进行风险成本管控</li>
              </ol>
            </div>
          </div>
          <div class="characteristic-box">
            <div class="box-content labour-characteristic-box">
              <h2>劳务统计</h2>
              <p>
                实时掌控劳务数据、打破数据孤岛，进行多维度交叉数据分析，实现数据辅助决策，达到降本增效的目的，提升企业竞争优势。
              </p>
              <ol>
                <li><i></i>实时浏览项目状态</li>
                <li><i></i>考勤数据自动统计</li>
                <li><i></i>劳务风险及时预警</li>
              </ol>
            </div>
            <div class="box-img">
              <img
                src="/images/labour/1-laborservice_characteristics_picture_laborstatistics.png"
                alt=""
              />
            </div>
          </div>
          <div class="characteristic-box">
            <div class="box-img">
              <img
                src="/images/labour/2-laborservice_characteristics_picture_checkinonattendance.png"
                alt=""
              />
            </div>
            <div class="box-content labour-characteristic-box">
              <h2>考勤打卡</h2>
              <p>
                人脸识别小程序，即开即用，操作简便易上手；码里盒子通过蓝牙定位，具有成本低、功耗小、工作时间长、现场无需WIFI、外置电源等优点，有效杜绝作弊现象，避免劳资纠纷。
              </p>
              <ol>
                <li><i></i>实名人脸识别即开即用</li>
                <li><i></i>操作快速简便容易上手</li>
                <li><i></i>蓝牙盒子精准定位打卡</li>
                <li><i></i>零成本部署安装更便捷</li>
              </ol>
            </div>
          </div>
          <div class="characteristic-box">
            <div class="box-content labour-characteristic-box">
              <h2>实名制</h2>
              <p>
                推进工人实名制，对接实名制平台，助力企业遍布全国各地的施工项目对接当地政府平台，满足监管需求，做到一个体系，一个标准。
              </p>
              <ol>
                <li><i></i>工人实名制规范管理</li>
                <li><i></i>衔接实名制平台数据</li>
                <li><i></i>支持独立部署和对接</li>
              </ol>
            </div>
            <div class="box-img">
              <img
                src="/images/labour/3-laborservice_characteristics_picture_realnamesystem.png"
                alt=""
              />
            </div>
          </div>
          <div class="characteristic-box">
            <div class="box-img">
              <img
                src="/images/labour/4-laborservice_characteristics_picture_worker'sfile.png"
                alt=""
              />
            </div>
            <div class="box-content labour-characteristic-box">
              <h2>工人档案</h2>
              <p>
                生成个人专属二维码，一码详细记录用工详情。系统内形成工人个人档案，记录工人进退场记录、合同、考勤培训、技术交底、工资发放等信息，有效防范劳资纠纷风险。
              </p>
              <ol>
                <li><i></i>个人专属二维码实名认证</li>
                <li><i></i>扫码可快速查询工人信息</li>
                <li><i></i>系统内自动形成工人档案</li>
                <li><i></i>自动推送交底资料给班组</li>
              </ol>
            </div>
          </div>
          <div class="characteristic-box">
            <div class="box-content labour-characteristic-box">
              <h2>班前教育</h2>
              <p style="width: 365px">
                班前教育详情快速记录，形成资料存档于云端，方便查找；自动推送交底资料，工人查阅情况一目了然，有效落实施工注意事项、作业要点、作业方法等提醒和培训。
              </p>
              <ol>
                <li><i></i>快速记录班前教育信息</li>
                <li><i></i>手机随时查看安全通病</li>
                <li><i></i>随时监测发送整改问题</li>
              </ol>
            </div>
            <div class="box-img">
              <img
                src="/images/labour/5-laborservice_characteristics_picture_pre-classeducation.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="content-branch">
        <div class="width-100 price-main-content-price" ref="PriceRef">
          <h2 style="font-size: 28px; font-weight: 400">价格方案</h2>
          <div class="labour-box">
            <h4 class="labour-pay-text">
              管理人员免费使用，班组付费即可得到全部体验
            </h4>
            <h4 class="labour-pay-text">工人限时每天仅需 8 分钱</h4>
          </div>
          <LWPrice />
        </div>
      </div> -->
    </div>
    <!-- <MyFooter /> -->
    <div style="background-color: rgba(0,0,0,0.8); color: #ccc; text-align: center; padding: 20px;">深圳市恒坤智慧科技有限公司</div>
  </div>
</template>

<script>
import NavComponent from "@/js/nav.js";
// import AsideComponent from "@/js/asideBackTop.js";
// import MyFooter from "@/components/Footer.vue";
import { getBannerByTag } from '@/js/serve.js'
// import LWPrice from '@/pages/priceMain/LWPrice.vue'

let componentNav = new NavComponent().$mount();
// let componentAside = new AsideComponent().$mount();

export default {
  components: {
    // MyFooter,
    // LWPrice
  },
  data() {
    return {
      dialogVisible: false,
      bannerList: [],
      //功能特点
      bottomCardList: [
        {
          title: "产品码",
          titleBtn: "免费试用",
          titleMini:
            "产品标签赋能升级，收发货线上自动流转，记录更多产品详情。采购流程标准化，告别错发、漏发、责任不明采供双方数据互通，降低采购沟通成本，节省对账周期",
          ol: [
            "一码自动线上流转",
            "码上保修售后维保",
            "采供双方数据互通",
            "自动生成材料对账",
          ],
          src: "/images/material/1-materials_characteristics_picture_productcode.png",
        },
        {
          title: "售后维保",
          titleBtn: "免费试用",
          titleMini:
            "码上报修，全程无忧。支持扫码添加文字、语音及图片进行报修，厂商快速获知维保信息，减少沟通和人力成本，维保记录清晰可查。厂商信息更全面，产品信息更立体，展示内容更多样。",
          ol: [
            "手机查看产品保修",
            "远程确认维修详情",
            "清晰可查维修记录",
            "产品信息案例展示",
          ],
          src: "/images/material/2-materials_characteristics_picture_productcode.png",
        },
        {
          title: "自动流转",
          titleBtn: "免费试用",
          titleMini:
            "从合同签单到线上接收料单，产品生产、试装、打包、发货、请款、维保全程自动流转，生产进度清晰可见，实时可控。",
          ol: ["人脸识别考勤打卡", "自动生成考勤汇总", "一码记录工人档案"],
          src: "/images/material/3-materials_characteristics_picture_productcode.png",
        },
      ],
      //四大优势
      advantageList: [
        {
          title: "新一代自主搭建的装饰项目劳务管理系统",
          content:
            "全过程精细化管理，所有模块可以根据真实情况自由修改、组合，为装饰企业量身打造的劳务管理系统。",
          ol: ["全过程精细化管理", "模块自由修改组合", "量身打造劳务系统"],
        },
        {
          title: "全程数字化管理，助力装企数字化转型",
          content:
            "将繁杂的、难以实时监控的劳务管理化繁为简、进行实时的动态管理，助力装饰企业提高劳务数字化管理水平。",
          ol: [
            "监控劳务管理化繁为简",
            "项目全程实时动态管理",
            "一站式全链路系统管理",
            "助力劳务数字化领导力",
          ],
        },
        {
          title: "有效避免磨洋工、虚报工程量和恶意讨薪",
          content:
            "工人档案、考勤记录、合同、工资发放等情况一目了然，减少甚至避免磨洋工、虚报工程量和恶意讨薪等行为。",
          ol: [
            "工人档案考勤记录好管理",
            "合同与工资发放一目了然",
            "避免磨洋工与虚报工程量",
            "规避恶意讨薪等行为风险",
          ],
        },
        {
          title: "高效便捷的施工现场劳务管理解决方案",
          content:
            "满足劳务人员信息收集、共享和使用，监督过程管理，降低劳资纠纷的风险，同时满足建筑装饰企业信息管理、安全管理以及跟踪分析的要求。",
          ol: [
            "劳务人员信息高效收集共享和使用",
            "降低劳资纠纷风险与监督过程管理",
            "满足建筑装饰企业信息管理的要求",
            "满足安全管理以及跟踪分析的要求",
          ],
        },
      ],
      //六大痛点
      painSpotList: [
        {
          title: "实名制管理不完善",
          content: "实名制管理混乱，劳务信息不完善，缺乏有力监管",
          align: "content-right",
          top: "50px",
          left: "130px",
        },
        {
          title: "工人档案不清晰",
          content: "劳务合同不规范，工人档案不清晰，无法有效及时监控",
          align: "content-right",
          top: "180px",
          left: "105px",
        },
        {
          title: "劳务数据不透明",
          content: "进场人数模糊，用工考勤数据混乱，项目现场缺乏信息化管理",
          content3: "品和厂商形象",
          align: "content-right",
          top: "320px",
          left: "130px",
        },
        {
          title: "劳务费用难把控",
          content: "劳务申请费用缺乏风险预警，无法及时纠偏，核查止损。",
          align: "content-left",
          width: "182px",
          top: "60px",
          left: "900px",
        },
        {
          title: "安全管理风险大",
          content: "安全施工规范执行不到位，容易造成安全事故。",
          align: "content-left",
          top: "180px",
          left: "920px",
        },
        {
          title: "劳务纠纷易频发",
          content:
            "没有可量化的用工实据及发薪依据，无法为劳资纠纷提供准确的法律依据。",
          align: "content-left",
          top: "300px",
          left: "900px",
        },
      ],
      wechatCode: true,
      productCard: false,
      priceCard: false,
      contactCard: false,
      scenesStyle: "scenes-title",
      paginationStyle: "pagination-content pagination-content-hidden",
      dynamicHeight: "{height:54px}",
      navBackgroundStyle: "",
      secondaryClass: "secondary-nav-box",
      asideList: [
        { src: "/images/index/icon_service@2x.png", text: "在线客服" },
        { src: "/images/index/icon_wechat@2x.png", text: "微信客服" },
        { src: "/images/index/icon_top@2x.png", text: "" },
      ],
      priceMainList: [
        {
          text: "行业痛点",
          className: "current",
          href: "",
        },
        {
          text: "四大优势",
          className: "",
          href: "",
        },
        {
          text: "功能特点",
          className: "",
          href: "",
        },
        // {
        //   text: "价格方案",
        //   className: "",
        //   href: "",
        // },
      ],
      priceNavCurrent: "", // nav选中的li
      imgCardList: [
        "/images/material/materials_advantage_01@2x.png",
        "/images/material/materials_advantage_02@2x.png",
        "/images/material/materials_advantage_03@2x.png",
        "/images/material/materials_advantage_04@2x.png",
      ],
      table1: [
        {
          td1: "套餐名称",
          td2: "个人、企业都能使用,解决基础需求",
          td3: "完整的生产企业内部生产,进销存管理,针对生产、收发货等核心业务场景,提供行业最优解决方案",
        },
        {
          td1: "收费标准",
          td2: "免费",
          td3: "20000元/年",
        },
        {
          td1: "功能简介",
          td2: "支持对接码里公装",
          td3: "使用人数50人",
          rowspan: "12",
        },
        {
          td1: "移动端小程序",
          td2: "10GB存储空间",
        },
        {
          td1: "使用人数2人",
          td2: "销售及库存完整功能",
        },
        {
          td1: "50M文件存储空间",
          td2: "采购管理完整功能",
        },
        {
          td1: "销售及库存基础功能",
          td2: "原材料管理完整功能",
        },
        {
          td1: "产品管理基础功能",
          td2: "工序管理",
        },
        {
          td1: "项目管理基础功能",
          td2: "全局数据统计",
        },
        {
          td1: "库存管理基础功能",
          td2: "全局表单导入导出",
        },
        {
          td1: "显示码里标识水印",
          td2: "自定义标签及打印",
        },
        {
          td1: "/",
          td2: "多渠道消息提醒",
        },
        {
          td1: "/",
          td2: "物流追踪",
        },
        {
          td1: "/",
          td2: "超高频RFID无感知验收",
        },
      ],
      table1Display: true,
      mainCard: ["功能", "免费版", "标准版"],
      navOffsetTop: 0,
    };
  },
  mounted() {
    // document.getElementById("backTop").appendChild(componentAside.$el);
    document.getElementById("navBox").appendChild(componentNav.$el);
    this.navOffsetTop = this.$refs.priceMainRef.offsetTop; // 记住nav初始的位置
    addEventListener("scroll", this.handelScroll);
    getBannerByTag({ platform_tag: "MaliHome", banner_tag: "produce_MaliLW_banner_background" }).then(res => {
      this.bannerList = (res.data.items || [])
    })
  },
  methods: {
    toLink(url) {
      if (url) {
        window.open(url);
      }
    },
    handleBtnEvent(command){
      if (command) {
        const commandArray = command.split('::')
        if (!commandArray[1]) return false
        switch (commandArray[0]) {
          case 'link':
            window.open(commandArray[1]);
            break;
          case 'action':
            this[commandArray[1]] && this[commandArray[1]]();
            break;
          default:
            break;
        }
      }
    },
    goPrice() {
      this.handellabourNav(3)
    },
    goPriceLabour() {
      window.open("priceLabour.html");
    },
    contactUs() {
      // document.getElementById('contactUs').click()
      window.open("contactUs.html");
    },
    //  监听鼠标滚动事件  // e = e || window.event
    handelScroll() {
      let navSetTop = this.$refs.priceMainRef.offsetTop;
      if (scrollY > 0) {
        // 如果滚动距离 > 0  ,顶部导航变色
        this.navBackgroundStyle = { background: "#fff" };
      } else {
        this.navBackgroundStyle = { display: "none" };
      }
      if (scrollY > navSetTop - 200) {
        this.navBackgroundStyle = { display: "none" };
        this.secondaryClass = "secondary-nav-box fix-nav pc-shadow"; // 固定到顶部
      }
      if (scrollY < this.navOffsetTop) {
        this.navBackgroundStyle = { background: "#fff" };
        this.secondaryClass = "secondary-nav-box";
      }
      if (scrollY == 0) {
        this.navBackgroundStyle = "";
      }
    },
    // 鼠标移入事件
    mouseoverSwiper(item) {
      console.log("111", item);
      item.paginationStyle = "pagination-content";
    },
    mouseleaveSwiper(item) {
      console.log("222", item);
      item.paginationStyle = "pagination-content pagination-content-hidden";
    },
    handelNav(value) {
      if (value === "productCard") {
        this.priceCard = false;
        this.contactCard = false;
      } else if (value === "priceCard") {
        this.productCard = false;
        this.contactCard = false;
      } else if (value === "contactCard") {
        this.productCard = false;
        this.priceCard = false;
      }
      this[value] = this[value] ? false : true;
    },
    colseWeChat() {
      console.log("关掉");
      this.wechatCode = false;
    },
    clickAside(val) {
      if (val == "微信客服") {
        this.wechatCode = true;
      } else {
        window.open(
          "https://kefu.easemob.com/webim/im.html?configId=d7458838-adde-427c-8618-fa40eb36e8c4"
        );
      }
    },
    handellabourNav(val) {
      this.priceMainList.map((item, index) => {
        if (index === val) {
          item.className = "current";
        } else {
          item.className = "";
        }
      });

      let mlAdvantage = this.$refs.mlAdvantageRef.offsetTop;
      let changeUs = this.$refs.changeUsRef.offsetTop;
      let trait = this.$refs.traitRef.offsetTop;
      // let price = this.$refs.PriceRef.offsetTop;

      if (val === 1) {
        // document.documentElement.scrollTop= changeUs - 120
        document.documentElement.scrollTop = mlAdvantage - 120;
      } else if (val === 2) {
        document.documentElement.scrollTop = trait - 120;
      } else if (val === 3) {
        // document.documentElement.scrollTop = price - 120;
      } else if (val === 0) {
        document.documentElement.scrollTop = changeUs - 120;
      }
    },
    changeDisplay(value) {
      if (value === 1) {
        this.table1Display = this.table1Display ? false : true;
      } else if (value === 2) {
        this.table2Display = this.table2Display ? false : true;
      }
    },
    buyBtn() {
      // if (val === "联系我们") {
      //   window.open("contactUs.html");
      // } else {
      //   window.open("https://lw.ddscan.cn");
      // }
    },
    // loginBtn() {
    //   window.open("https://saas.ddscan.cn");
    //   // this.dialogVisible = true
    // }
  },
};
</script>
 
<style scoped>
</style>